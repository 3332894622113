<template>
    <div>
        <el-row style="display: flex; align-items: center;">
            <el-col :span="22" class="component-title" v-if="!isMobile()">
                Yooni Fit: <strong>{{$store.state.counsellorStudentName}}</strong>
            </el-col>
            <el-col v-else>
                &nbsp;
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-tabs id="yooniFitTabs" :stretch="true" :style="getStyle()" @tab-click="showCourseFit=false"
                 v-model="activeName">
            <el-tab-pane label="Course Fit" name="course_fit">
                <el-row v-if="getRecommendation" id="courseLevel">
                    <div style="z-index: 9; right: 0;"
                         :style="!isMobile() ? {position: 'absolute', right: 'calc(1vw + 10px)'}:{float: 'right'}">
                        <el-radio-group style="background: transparent;" v-model="$store.state.courseLevelActiveName"
                                        class="selectLevel">
                            <el-radio-button :title="'8'" size="mini"
                                             :label="'8'" border>
                                Level 8
                            </el-radio-button>
                            <el-radio-button :title="'6_7'" size="mini"
                                             :label="'6_7'" border>
                                Level 6/7
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                    <web-course-yooni-fit
                            :level="$store.state.courseLevelActiveName"
                            v-if="!isMobile()">
                    </web-course-yooni-fit>
                    <mobile-course-yooni-fit
                            :level="$store.state.courseLevelActiveName"
                            v-else-if="isMobile()">
                    </mobile-course-yooni-fit>
                </el-row>

            </el-tab-pane>
            <el-tab-pane label="Career Fit" name="career_fit">
                <career-fit-result v-if="getCareerResult"></career-fit-result>
            </el-tab-pane>
            <el-tab-pane label="Personality Fit" name="personality_fit">
                <personality-fit-result v-if="getPersonalityTestResult"></personality-fit-result>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
  import WebCourseYooniFit from '../../page_components/YooniFitComponents/web/WebCourseYooniFit';
  import MobileCourseYooniFit from '../../page_components/YooniFitComponents/mobile/MobileCourseYooniFit';
  import CareerFitResult from '../../page_components/YooniFitComponents/CareerFitResult';
  import PersonalityFitResult from '../../page_components/YooniFitComponents/PersonalityFitResult';
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'CounsellorYooniFit',
    components: {
      ProfileAvatar, PersonalityFitResult, CareerFitResult, MobileCourseYooniFit, WebCourseYooniFit,
    },
    props: ['studentId', 'studentName'],
    mounted() {
      let self = this;
      self.checkUserLoggedIn().then(response => {
        if (self.$store.state.isAdmin) {
          self.$router.push('/admin/');
        }
        if (self.studentId === undefined || self.studentName === undefined) {
          self.$router.push('/counsellor/home/');
        }
        self.$store.state.counsellorStudentId = self.studentId;
        self.$store.state.counsellorStudentName = self.studentName;
        self.$store.state.showSidebar = true;
        self.getStudentYooniFit();
        self.getStudentCareerFitResults();
        self.getStudentPersonalityFitResults();
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    computed: {
      getRecommendation: function() {
        return this.recommendations;
      },
      getCareerResult: function() {
        return this.careerTestResults;
      },
      getPersonalityTestResult: function() {
        return this.personalityTestResults;
      },
    },
    data: function() {
      return {
        courseLevelActiveName: '8',
        loadingScreen: false,
        loadingMessage: '',
        careerTestResults: false,
        personalityTestResults: false,
        recommendations: false,
        activeName: 'course_fit',
      };
    },
    methods: {
      getStyle: function() {
        let styleObject = {};
        styleObject['width'] = '100%';
        if (this.isMobile()) {
          styleObject['margin-top'] = '0';
        } else {
          // styleObject['margin-top'] = '-0.5%';
        }
        return styleObject;
      },
      getStudentCareerFitResults: function() {
        let self = this;
        let url = self.getBaseUrl() + '/recommendations/student/test1/' + self.studentId + '/';
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.careerTestResults = response;
            self.setCareerTestResults(response);
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            console.log(error);
            self.$store.state.loadingScreen = false;
          },
        });
      },
      getStudentPersonalityFitResults: function() {
        let self = this;
        let url = self.getBaseUrl() + '/recommendations/student/test2/' + self.studentId + '/';
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.personalityTestResults = response;
            self.setPersonalityTestResults(response);
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            console.log(error);
            self.$store.state.loadingScreen = false;
          },
        });
      },
      getStudentYooniFit: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        let url = self.getBaseUrl() + '/recommendations/student/report/' + self.studentId + '/';
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.$store.state.loadingScreen = false;
            self.recommendations = response;
            self.setRecommendations(response);
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
            console.log(error);
          },
        });
      },
    },
  };
</script>

<style scoped>

</style>
